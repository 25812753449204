.checkoutPage {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.checkoutPageContent {
  margin-top: 6rem;
  flex: 1;
}

.checkoutPageTotal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50rem;
  align-items: center;
  margin: 0rem auto 0rem auto;
}
