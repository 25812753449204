.header {
  height: 8rem;
  width: 66rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.logo {
  font-weight: 1000;
  font-size: 3rem;
  letter-spacing: -0.2rem;
}

.logoLink {
  text-decoration: none;
  color: #0e0e2c;
}

.checkout-button,
.checkout-button:visited {
  padding: 0.6rem 2rem 0.6rem 2rem;
  box-sizing: border-box;
  border: 0.15rem solid #0e0e2c;
  border-radius: 3rem;
  color: #0e0e2c;
  text-decoration: none;
  font-size: 1.5rem;
}
