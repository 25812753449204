.product {
  position: relative;
  width: 400px;
  height: 400px;
}

.productImg {
  height: 90%;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.productBuyButton {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  padding: 0.6rem 2rem 0.6rem 2rem;
  box-sizing: border-box;
  border: 0.15rem solid #0e0e2c;
  border-radius: 3rem;
  color: #0e0e2c;
  font-size: 1rem;
  background-color: white;
  cursor: pointer;
}

.product:hover .productImg {
  opacity: 0.3;
}

.product:hover .productBuyButton {
  opacity: 1;
}
